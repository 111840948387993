import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container, Grid, Toolbar, Fab, useTheme, useMediaQuery, Typography } from '@mui/material';
import {  addBlacklistForCompany, deleteBlacklistForCompany, getBlacklistsForCompany } from '../../controllers/api';
import { useCurrentCompany } from '../../utils/hooks';
import { BlacklistTable } from '../../components/BlacklistTable';
import AddBlacklistDialog from './AddBlacklistDialog';
import { useToast } from '../../components/Toast';

export const BlacklistPage = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { showToast } = useToast();

    const [
        state,
        setState
    ] = useState({
        blacklistLoading: false,
        blacklistDialogOpen: false,
        blacklist: [],
        page: 0,
        rowsPerPage: 10,
    });

    const {
        currentCompany,
    } = useCurrentCompany();

    const handleBlacklistDelete = async (id) => {

        setState(prevState => ({
            ...prevState,
            blacklistLoading: true,
        }));

        try {
            const response = await deleteBlacklistForCompany(currentCompany, id);
            setState(prevState => ({
                ...prevState,
                blacklistLoading: false,
            }));
            showToast('Blacklist entry deleted successfully', 'success', 3000);
            refreshBlacklistEntries();
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                blacklistLoading: false,
            }));
            showToast('Failed to delete blacklist entry', 'error', 3000);
        }
    }

    const handleBlacklistSubmit = async (value, description) => {


        setState(prevState => ({
            ...prevState,
            blacklistLoading: true,
        }));


        try {

            const response = await addBlacklistForCompany(currentCompany, value, description);
            setState(prevState => ({
                ...prevState,
                blacklistLoading: false,
            }));
            showToast('Blacklist entry added successfully', 'success', 3000);
            refreshBlacklistEntries();
            return true;
        } catch (error) {

            setState(prevState => ({
                ...prevState,
                blacklistLoading: false,
            }));
            showToast('Failed to add blacklist entry', 'error', 3000);
            return false;
        }
    };

    const refreshBlacklistEntries = React.useCallback(async () => {

        setState(prevState => ({
            ...prevState,
            blacklistLoading: true,
        }));

        const response = await getBlacklistsForCompany(currentCompany);
        if (response.data) {

            setState(prevState => ({
                ...prevState,
                blacklist: response.data,
            }));
        }

        setState(prevState => ({
            ...prevState,
            blacklistLoading: false,
        }));
    }, []);

    useEffect(() => {
        refreshBlacklistEntries();
    }, [
        refreshBlacklistEntries,
    ]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setState(prevState => ({
            ...prevState,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10),
        }));
    };


    return <Box
        component="main"
        sx={{
            flexGrow: 1,
            height: '100%',
        }}
    >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4, mx: 2, }}>
            <Box marginBottom={3}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Blacklist
                </Typography>
                <Typography component="small" color="GrayText">
                    Add and manage blacklist entries. Blacklisted entries will be denied access to the premises. Either ID number or vehicle registration can be blacklisted.
                </Typography>
            </Box>

            {/* Blacklist Table */}
            <Grid item xs={12}>
                {state.blacklistLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : (
                    <BlacklistTable
                        handleBlacklistDelete={handleBlacklistDelete}
                        handleChangePage={handleChangePage}
                        blacklist={state.blacklist}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        page={state.page}
                        rowsPerPage={state.rowsPerPage}
                    />
                )}
            </Grid>

            <Box height={'400px'}></Box>

            <Box sx={{ '& > :not(style)': { m: 1 }, position: 'fixed', bottom: 16, right: 16 }}>
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    sx={{ mb: 2 }}
                    disabled={state.blacklistLoading}
                    onClick={() => setState(prevState => ({
                        ...prevState,
                        blacklistDialogOpen: true,
                    }))}>

                    + Add to Blacklist
                </Fab>
            </Box>
        </Container>
        <AddBlacklistDialog
            open={state.blacklistDialogOpen}
            onBlacklistSubmit={handleBlacklistSubmit}
            onClose={() => {
                setState(prevState => ({
                    ...prevState,
                    blacklistDialogOpen: false,
                }));
            }}
        />
    </Box>
}