import React, { useState, useEffect } from 'react';
import { Box, Card, CircularProgress, Container, Grid, TextField, Toolbar, Select, MenuItem, InputLabel, FormControl, Button, Fab, useTheme, useMediaQuery } from '@mui/material';
import { getVisits, getSites, downloadLogsReport } from '../../controllers/api';
import { useCurrentCompany } from '../../utils/hooks';
import { LogsTable } from '../../components/LogsTable';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { RefreshRounded } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { VisitReports } from './VisistReports';

export const LogsPage = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [
        state,
        setState
    ] = useState({
        siteId: '',
        highlightLogId: undefined,
        fromDate: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        toDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        logs: [] as any[],
        loading: false,
        page: 0,
        pageSize: 10,
        visitReport: undefined as any,
        sites: [] as any[],
        reportLoading: false,
    });

    const {
        currentCompany,
        setCurrentCompany,
    } = useCurrentCompany();

    const location = useLocation();

    const queryParams = React.useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const handleReportDownload = async () => {

        // get email from user 
        let email: any = prompt('Please enter email address to receive the report\n\n(leave blank to download directly)');
        if (email === '') {
            email = undefined;
        }

        if (state.reportLoading) {
            return;
        }
        setState(prevState => ({
            ...prevState,
            reportLoading: true,
        }));

        try {
            let result = await downloadLogsReport(currentCompany, state.siteId, state.fromDate, state.toDate, email);

            let reportUrl = result.data?.reportUrl ?? '';
            if (reportUrl === '') {
                return;
            }

            window.open(reportUrl);
        } finally {
            setState(prevState => ({
                ...prevState,
                reportLoading: false,
            }));
        }
    }

    const handleGetSites = async () => {
        setState(prevState => ({
            ...prevState,
            loading: true,
        }));

        const response = await getSites(currentCompany);
        if (response.data) {
            setState(prevState => ({
                ...prevState,
                sites: response.data,
                siteId: response.data[0]?.siteCode,
            }));
        }

        setState(prevState => ({
            ...prevState,
            loading: false,
        }));
    }

    useEffect(() => {
        handleGetSites();
    }, [currentCompany]);

    const refreshVisits = React.useCallback(async () => {
        if (state.siteId && state.fromDate && state.toDate) {


            setState(prevState => ({
                ...prevState,
                loading: true,
            }));


            getVisits(state.siteId, new Date(state.fromDate), new Date(state.toDate), state.page, state.pageSize)
                .then((res) => {
                    setState(prevState => ({
                        ...prevState,
                        logs: res.data.visits,
                        visitReport: res.data.visitReport,                        
                        loading: false,
                    }));
                })
                .catch((err) => {
                    console.error(err);
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                    }));
                });
        }
    }, [
        state.siteId,
        state.fromDate,
        state.toDate,
        state.page,
        state.pageSize,        
    ]);

    useEffect(() => {
        refreshVisits();
    }, [
        state.siteId,
        state.fromDate,
        state.toDate,
        state.page,
        state.pageSize,
        refreshVisits,
    ]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setState(prevState => ({
            ...prevState,
            page: newPage,
        }));
    };

    const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            page: 0,
            pageSize: parseInt(event.target.value, 10),
        }));
    };

    React.useEffect(() => {
        // set logId, startDate, endDate from query params        
        const _logId = queryParams.get('logId');
        const _fromDate = queryParams.get('fromDate');
        const _toDate = queryParams.get('toDate');
        const _siteId = queryParams.get('siteId');

        console.log('got query params', {
            _logId,
            _fromDate,
            _toDate,
            _siteId,
            sites: state.sites,
        });

        const selectedSite = state.sites.filter((s) => s.siteCode && s.siteCode.toUpperCase() == _siteId?.toUpperCase());
        if (_siteId && _fromDate && _toDate && state.sites.length >= 1 && selectedSite.length == 1) {

            setState(prevState => ({
                ...prevState,
                siteId: _siteId.toUpperCase(),
                fromDate: _fromDate,
                toDate: _toDate,
                highlightLogId: _logId as any,
            }));
        } else {
            console.log('not this time')
        }
    }, [
        queryParams,
        state.sites,
    ]);

    return <Box
        component="main"
        sx={{
            flexGrow: 1,
            height: '100%',
            overflow: 'auto',
        }}
    >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} direction={isSmallScreen ? 'column' : 'row'} alignItems="flex-start">
                {/* Filter Section */}
                <Card elevation={3} sx={{ marginY: 1, marginX: 2 }} style={{
                    width: '100%',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <FormControl
                                fullWidth
                                margin="normal">
                                <InputLabel id="site-select-label">Site ID</InputLabel>
                                <Select
                                    labelId="site-select-label"
                                    id="site-select"
                                    value={state.siteId}
                                    label="Site ID"
                                    onChange={(e) => setState(prevState => ({
                                        ...prevState,
                                        siteId: e.target.value as string
                                    }))}>
                                    {state.sites.map((site) => (
                                        <MenuItem key={site.siteCode} value={site.siteCode}>
                                            {site.siteName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="From Date"
                                type="date"
                                value={state.fromDate}
                                onChange={(e) => setState(prevState => ({
                                    ...prevState,
                                    fromDate: e.target.value,
                                }))}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="To Date"
                                type="date"
                                value={state.toDate}
                                onChange={(e) => setState(prevState => ({
                                    ...prevState,
                                    toDate: e.target.value,
                                }))}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                    style: { color: "white" }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} alignContent={'center'} alignItems={'center'} alignSelf={'center'}>
                            {/* Refresh Button  */}
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={refreshVisits}
                                startIcon={<RefreshRounded />}
                                style={{
                                    height: '50%',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                }}
                            >
                                Refresh
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            {/* Reports */}
            <VisitReports
                graphData={state.visitReport}/>

            {/* Logs Table */}
            <Grid item xs={12}>
                {state.loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : (
                    <LogsTable
                        handleChangePage={handleChangePage}
                        logs={state.logs}
                        totalCount={state.visitReport?.totalVisits ?? 0}
                        page={state.page}
                        rowsPerPage={state.pageSize}
                        handleChangeRowsPerPage={handleChangePageSize}
                    />
                )}
            </Grid>

            <Box height={'400px'}></Box>

            {/* Floating Action Buttons */}
            <Box sx={{ '& > :not(style)': { m: 1 }, position: 'fixed', bottom: 16, right: 16 }}>
                <Fab variant="extended" color="primary" aria-label="add" sx={{ mb: 2 }} onClick={handleReportDownload} disabled={state.reportLoading}>
                    {
                        state.reportLoading ? (
                            <CircularProgress sx={{ marginRight: 2 }} />
                        ) : (
                            <AnalyticsIcon sx={{ marginRight: 2 }} />
                        )
                    }
                    Generate Report
                </Fab>
                <Fab variant="extended" color="secondary" aria-label="edit" disabled>
                    <QueryStatsIcon sx={{ mr: 1 }} />
                    Lookup Query
                </Fab>
            </Box>
        </Container>        
    </Box>
}

// return (
//     <Box
//         component="main"
//         sx={{
//             flexGrow: 1,
//             height: '100%',
//             overflow: 'auto',
//         }}
//     >
//         <Toolbar />
//         <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
//             <Grid container spacing={3}>
//                 <Grid item xs={12} md={4}>
//                     <Box marginBottom={3}>
//                         <Title>Filter Results</Title>
//                     </Box>
//                     <Card elevation={3} sx={{ marginBottom: 3 }}>
//                         <Box p={3}>
//                             {/* <Box marginBottom={2}> */}
//                             {/* <CompanyDropdown /> */}
//                             {/* </Box> */}
//                             <FormControl fullWidth>
//                                 <InputLabel id="site-select-label">Site ID</InputLabel>
//                                 <Select
//                                     labelId="site-select-label"
//                                     id="site-select"
//                                     value={state.siteId}
//                                     label="Site ID"
//                                     onChange={(e) => setState(prevState => ({
//                                         ...prevState,
//                                         siteId: e.target.value as string
//                                     }))}>
//                                     {state.sites.map((site) => (
//                                         <MenuItem key={site.siteCode} value={site.siteCode}>
//                                             {site.siteName}
//                                         </MenuItem>
//                                     ))}
//                                 </Select>
//                             </FormControl>
//                             <TextField
//                                 label="From Date"
//                                 type="date"
//                                 value={state.fromDate}
//                                 onChange={(e) => setState(prevState => ({
//                                     ...prevState,
//                                     fromDate: e.target.value,
//                                 }))}
//                                 fullWidth
//                                 margin="normal"
//                                 InputLabelProps={{
//                                     shrink: true,
//                                 }}
//                             />
//                             <TextField
//                                 label="To Date"
//                                 type="date"
//                                 value={state.toDate}
//                                 onChange={(e) => setState(prevState => ({
//                                     ...prevState,
//                                     toDate: e.target.value,
//                                 }))}
//                                 fullWidth
//                                 margin="normal"
//                                 InputLabelProps={{
//                                     shrink: true,
//                                     style: { color: "white" }
//                                 }}
//                             />
//                         </Box>
//                     </Card>
//                     <Box marginBottom={3}>
//                         <Title>Advanced Options</Title>
//                     </Box>
//                     <Card elevation={3} sx={{ mt: 3 }}>
//                         <Box p={3} alignContent={'center'}>
//                             <Button
//                                 sx={{ mb: 2 }}
//                                 variant="contained"
//                                 fullWidth
//                                 disabled={state.reportLoading}
//                                 onClick={handleReportDownload}
//                             >
//                                 {
//                                     state.reportLoading ? (
//                                         <CircularProgress sx={{ marginRight: 2 }} />
//                                     ) : (
//                                         <AnalyticsIcon sx={{ marginRight: 2 }} />
//                                     )
//                                 }
//                                 Generate Report
//                             </Button>

//                             <Button
//                                 variant="contained"
//                                 fullWidth
//                                 disabled
//                             >
//                                 <QueryStatsIcon sx={{ marginRight: 2 }} />
//                                 Lookup Query
//                             </Button>



//                             <Box
//                                 display="flex"
//                                 justifyContent="center"
//                                 alignItems="flex-center"
//                                 marginTop={3} color={'grey'}>

//                                 <LockPersonIcon sx={{ mr: 2 }} />
//                                 <span>
//                                     Disabled
//                                 </span>
//                             </Box>
//                         </Box>
//                     </Card>
//                 </Grid>
//                 <Grid item xs={12} md={8}>
//
//                 </Grid>
//             </Grid>
//         </Container>
//     </Box >
// );
// }



