import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Registering the components required for our chart
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const VisitChart = ({graphData}) => {

    React.useEffect(() => {}, []);

    // Example data
    const labels = graphData.DATA_DAILY_ACTIVITY_LABELS;
    const dataEntries = graphData.DATA_DAILY_ACTIVITY_ENTRIES;
    const dataExits = graphData.DATA_DAILY_ACTIVITY_EXITS;

    const data = {
        labels,
        datasets: [
            {
                label: 'Entries',
                data: dataEntries,
                backgroundColor: 'rgba(75, 192, 192, 0.4)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Exits',
                data: dataExits,
                backgroundColor: 'rgba(255, 99, 132, 0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            }
        ],
    };

    return <Bar data={data} options={{
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: 'Daily Activity (Entries/Exits)',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
        },
    }} />;
};
