import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CompanyInfo } from './CompanyInfo';
import { SAMapCard } from '../../components/SAMapCard';
import { Devices } from './Devices';
import { Sites } from './Sites';
import { LatestActivity } from './LatestActivity';
import { Copyright } from '../../components/Copyright';
import { Box, Button, CircularProgress } from '@mui/material';
import { useCurrentCompany } from '../../utils/hooks';
import { getSites } from '../../controllers/api';
import Title from './Title';
import { isAdmin, isSuperAdmin } from '../../utils/helpers';
import CompanyDialog from '../dialogs/CompanyDialog';
import EditIcon from '@mui/icons-material/Edit';
import { UserManagementSection } from './UserManagement';
import { ScheduledReportSection } from './ScehduledReports';

export default function Dashboard() {
  const { currentCompany, setCurrentCompany } = useCurrentCompany();
  const [loading, setLoading] = React.useState(false);
  const [siteData, setSiteData] = React.useState([] as any[]);
  const [showCompanyDialog, setShowCompanyDialog] = React.useState(false);
  const [showCompanyEditDialog, setShowCompanyEditDialog] = React.useState(false);


  const handleGetSites = async () => {

    try {
      setLoading(true);
      const response = await getSites(currentCompany);

      await new Promise((resolve) => setTimeout(resolve, 5000));

      setSiteData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSiteData([]);
    }

  }

  React.useEffect(() => {
    handleGetSites();
  }, [currentCompany]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100vw',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Box marginBottom={3} >
          <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
            <Title>Dashboard</Title>
            {isSuperAdmin() && (
              <Button
                onClick={() => setShowCompanyDialog(true)}
                color="primary" aria-label="add device" variant='outlined'>
                Add Company +
              </Button>
            )}
          </Grid>
        </Box>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={6} lg={6}>
            <CompanyInfo
              onEditClicked={() => {
                setShowCompanyEditDialog(true);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper
              elevation={3}
              sx={{
                p: 5,
                justifyContent: 'center',
                alignItems: 'center',
                height: 500,
                width: 500,
                marginX: 'auto',
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}
            >
              <SAMapCard siteList={siteData} />
            </Paper>
          </Grid>


          <Grid item xs={12} md={6}>
            <Sites
              siteList={siteData}
              onShouldRefresh={() => {
                handleGetSites();
              }} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Devices />
          </Grid>

          {
            isSuperAdmin() &&
            <Grid item xs={12} md={12}>
              <UserManagementSection
                siteOptions={siteData}/>
            </Grid>
          }

          {
            isAdmin() &&
            <Grid item xs={12} md={12}>

              <ScheduledReportSection
                siteList={siteData} />
            </Grid>

          }

          {/* <Grid item xs={12} md={12}>
            <LatestActivity siteList={siteData} />
          </Grid> */}
        </Grid>



        <Copyright sx={{ pt: 4 }} />
      </Container>

      <CompanyDialog
        isEdit={false}
        handleClose={() => { setShowCompanyDialog(false) }}
        open={showCompanyDialog} />

      <CompanyDialog
        isEdit={true}
        handleClose={() => { setShowCompanyEditDialog(false) }}
        open={showCompanyEditDialog} />
    </Box>
  );
}
