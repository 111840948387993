import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { VisitInfoDialog } from "./VisitDialog";

type LogPageProps = { logs: any[], page: number, rowsPerPage: number, handleChangePage: (event: unknown, newPage: number) => void, handleChangeRowsPerPage: any, totalCount: number, };

export const LogsTable = ({ logs, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, totalCount, }: LogPageProps) => {
    return <TableContainer
        component={Paper}
        sx={{ padding: 2 }}
    >
        <Table
            size="small"
        // sx={{ bgcolor: (theme) => theme.palette.background.default }}
        >
            <TableHead>
                <TableRow>
                    <TableCell>Event Date</TableCell>
                    <TableCell>Site ID</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Event Type</TableCell>
                    <TableCell>Access Type</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {logs.map((row) => (
                    <TableRow key={row.logId}>
                        <TableCell>{new Date(row.eventDate).toLocaleString()}</TableCell>
                        <TableCell>{row.siteId}</TableCell>
                        <TableCell>{row.deviceId}</TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="flex-start"
                            >
                                {row.eventType === 'EXIT' ? <ExitToAppIcon /> : <ExitToAppIcon />}
                                {row.eventType}
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="flex-start"
                            >
                                {row.accessType === 'PEDESTRIAN' ? <DirectionsWalkIcon /> : row.accessType === 'CONTRACTOR' ?  <EngineeringIcon/>  : <DriveEtaIcon /> }
                                {row.accessType}
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="flex-start"
                            >
                                <VisitInfoDialog visit={row} />
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

        <TablePagination
            // sx={{ bgcolor: (theme) => theme.palette.background.default }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} />
    </TableContainer>;
}
