import { Box, Card, CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material"
import { VisitChart } from "../reports/VisitChart"
import React from "react";
import Title from "../dashboard/Title";
import { countEntriesExitsPerDay } from "../../utils/report_helpers";

export const VisitReports = ({
    graphData,
}) => {

    if (!graphData) {
        return <React.Fragment />
    }

    return <Box marginY={2}>
        <Card
            elevation={3}
            sx={{
                mt: 3,
                p: 3,
            }}
        >
            <VisitChart graphData={graphData}/>
        </Card>

        {/* Counts Cards */}
        <Grid container spacing={3} alignItems="flex-start" marginTop={1}>
            <Grid item xs={12} md={3}>
                <Card elevation={3} style={{
                    width: '100%',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <Title>Visits</Title>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box fontSize={32} fontWeight={500}>
                            {graphData.VISIT_COUNT}
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card elevation={3} style={{
                    width: '100%',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <Title>Deliveries</Title>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box fontSize={32} fontWeight={500}>
                        {graphData.DELIVERY_COUNT}                            
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card elevation={3} style={{
                    width: '100%',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <Title>Pedestrians</Title>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box fontSize={32} fontWeight={500}>
                        {graphData.PEDESTRIAN_COUNT}
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card elevation={3} style={{
                    width: '100%',
                    padding: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <Title>Contractors</Title>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box fontSize={32} fontWeight={500}>
                            {graphData.CONTRACTOR_COUNT}
                        </Box>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    </Box>;
}