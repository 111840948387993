import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableRow, TableCell, Button, Box, Link } from '@mui/material';
import { Visit } from '../utils/models';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import S3DialogLink from './S3DialogLink';
import LockIcon from '@mui/icons-material/Lock';
import { getImage } from '../controllers/api';
import EngineeringIcon from '@mui/icons-material/Engineering';

interface VisitInfoProps {
    visit: Visit;
}

const isEmpty = (value: string | undefined) => {
    return value === undefined || value === null || value === '';
}


export const VisitInfoDialog: React.FC<VisitInfoProps> = ({ visit }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = async () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleOpen}>View Info</Button>

            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>
                    Access Log <br />
                    <Box color={'grey'} fontSize={'0.85rem'}>
                        #{visit.logId}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Access Type</TableCell>
                                <TableCell>
                                    <Box
                                        display="flex"
                                        justifyContent="start"
                                        alignItems="flex-start"
                                    >
                                        {visit.accessType === 'PEDESTRIAN' ? <DirectionsWalkIcon /> : visit.accessType == 'CONTRACTOR' ? <EngineeringIcon /> : <DriveEtaIcon />}
                                        {visit.accessType}
                                    </Box>

                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Event Type</TableCell>
                                <TableCell>
                                    <Box
                                        display="flex"
                                        justifyContent="start"
                                        alignItems="flex-start"
                                    >
                                        {visit.eventType === 'EXIT' ? <ExitToAppIcon /> : <ExitToAppIcon />}
                                        {visit.eventType}
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Entry Date</TableCell>
                                <TableCell>{new Date(visit.eventDate).toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Site ID</TableCell>
                                <TableCell>{visit.siteId}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Device ID</TableCell>
                                <TableCell>{visit.deviceId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Visit Info</TableCell>
                                <TableCell>
                                    {buildVisitInfo(visit)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Person Info</TableCell>
                                <TableCell>
                                    {buildPersonInfo(visit)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Vehicle Info</TableCell>
                                <TableCell>
                                    {buildVehicleInfo(visit)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Box
                        display="flex"
                        flexDirection={'row'}
                        justifyContent="center"
                        alignItems="center"
                        marginTop={4} color={'grey'}
                        textAlign={'center'}
                        width={'100%'}
                    >

                        <LockIcon color='primary' />
                        <Box color={'grey'}>
                            Secured & Encrypted
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog >
        </>
    );
};


const buildPersonInfo = (visit: Visit) => {

    const personImg = visit?.personImg;
    const personFullName = visit?.person?.fullName;
    const personIdNumber = visit?.person?.idNumber;
    const phoneNumber = visit?.person?.phoneNumber;

    if (isEmpty(personImg) && isEmpty(personFullName) && isEmpty(personIdNumber) && isEmpty(phoneNumber)) {
        return <Box color={'grey'}>No person info available</Box>
    }

    const isContractor = visit.accessType === 'CONTRACTOR';

    return (
        <>
            <div>
                {!isEmpty(personFullName) && <div>Name: {personFullName}</div>}
                {!isEmpty(personIdNumber) && isContractor && <div>Contractor Number: {personIdNumber}</div>}
                {!isEmpty(personIdNumber) && !isContractor && <div>ID Number <span><LockIcon color='primary' sx={{ fontSize: '0.95rem' }} /></span>  **********</div>}
                {!isEmpty(phoneNumber) && <div>Phone Number: {phoneNumber}</div>}
                {!isEmpty(personImg) && <div>
                    {/* Link */}
                    <S3DialogLink
                        objectKey={personImg} />
                </div>}
            </div>
        </>
    );
}


const buildVehicleInfo = (visit: Visit) => {

    const vehicleImg = visit?.vehicleImg;
    const vehicleDesc = visit?.vehicle?.vehicleDesc;
    const vehiclePlate = visit?.vehicle?.vehiclePlate;
    const vehicleVIN = visit?.vehicle?.vehicleVIN;

    if (isEmpty(vehicleImg) && isEmpty(vehicleDesc) && isEmpty(vehiclePlate)) {
        return <Box color={'grey'}>No vehicle info available</Box>
    }

    return (
        <>
            <div>
                {!isEmpty(vehicleDesc) && <div>Description: {vehicleDesc}</div>}
                {!isEmpty(vehiclePlate) && <div>License Plate: {vehiclePlate}</div>}
                {!isEmpty(vehicleVIN) && <div>VIN  <span><LockIcon color='primary' sx={{ fontSize: '0.95rem' }} /></span>  **********</div>}
                {!isEmpty(vehicleImg) && <div>
                    {/* Link */}
                    <S3DialogLink
                        objectKey={vehicleImg} />
                </div>}
            </div>
        </>
    );
}




const buildVisitInfo = (visit: Visit) => {    
    return <div>
            {visit?.visitInfo?.visitPerson}
            {visit?.visitInfo?.visitPerson && <br />}
            {visit?.visitInfo?.visitReason}
            <br />
            {buildPassengerInfo(visit)}
            {buildCustomFields(visit)}        
            {buildPhotoLinks(visit.entryPhotoLinks)}
        </div>
}


const buildPhotoLinks = (entryPhotoLinks: string[]) => {
    if (entryPhotoLinks.length === 0) {
        return <Box color={'grey'}>No photos available</Box>
    }

    return (
        <>
            {entryPhotoLinks.map((link, index) => {
                return (
                    <Box key={index}>
                        <S3DialogLink
                            objectKey={link} />
                    </Box>
                )
            })}
        </>
    );
}


const buildPassengerInfo = (visit: Visit) => {
    const passengerDetailsMapArray = visit?.passengers ?? [];
    if (passengerDetailsMapArray.length === 0) {
        return <Box></Box>
    }

    // passengerDetailsMapArray
    // [{'LABEL', 'VALUE', .......},]

    return (
        <>    
            <Box>
                {passengerDetailsMapArray.map((passengerDetailsMap, index) => {
                    return (
                        <Box key={index}>
                            {Object.keys(passengerDetailsMap).map((key, index) => {
                                return (
                                    <Box key={index}>
                                        {key}: {passengerDetailsMap[key]}
                                    </Box>
                                )
                            })}                         
                        </Box>
                    )
                })}
            </Box>
        </>
    );
}


const buildCustomFields = (visit: Visit) => {
    // {}
    const customFields = visit?.customFields ?? {};
    if (Object.keys(customFields).length === 0) {
        return <Box ></Box>
    }

    return (
        <>
            <Box>
                {Object.keys(customFields).map((key, index) => {
                    if (isEmpty(customFields[key])) {
                        return <Box key={index}></Box>
                    }

                    return (
                        <Box key={index}>
                            {key}:
                            {customFields[key]}
                        </Box>
                    )
                })}
            </Box>
        </>
    );
}


{/* <TableRow key={row.logId}>
<TableCell></TableCell>
<TableCell>{row.siteId}</TableCell>
<TableCell>{row.deviceId}</TableCell>
<TableCell>
    <Box
        display="flex"
        justifyContent="start"
        alignItems="flex-start"
    >
        {row.eventType === 'EXIT' ? <ExitToAppIcon /> : <ExitToAppIcon />}
        {row.eventType}
    </Box>
</TableCell>
<TableCell>
    <Box
        display="flex"
        justifyContent="start"
        alignItems="flex-start"
    >
        {row.accessType === 'PEDESTRIAN' ? <DirectionsWalkIcon /> : <DriveEtaIcon />}
        {row.accessType}
    </Box>
</TableCell>
<TableCell>
    <Box
        display="flex"
        justifyContent="start"
        alignItems="flex-start"
    >
        <VisitInfoDialog visit={row} />
    </Box>
</TableCell>
</TableRow> */}